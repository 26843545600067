<template>
  <div class="users-component">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <PageHeader></PageHeader>
    <Widget class="full-height-widget">
      <DataTable
        class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        :value="getUsers"
        :paginator="true"
        :filters="filters"
        :loading="loading"
        responsiveLayout="scroll"
        :rows="500"
        dataKey="id"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 100, 500, 1000]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">{{ $t('users') }}</h1>
            </div>
            <div class="table-header-right">
              <!-- <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText type="text" v-model="filters['global']" :placeholder="$t('search')" />
              </span> -->
              <div class="button-right">
                <router-link class="btn btn-success mb-2 right" :to="{ name: 'UserCreatePage' }">
                  <i class="fa fa-plus-circle mr-2"></i>
                  {{ $t('user_create') }}
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>

        <Column
          field="number"
          header="#"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ width: '60px' }"
          :bodyStyle="'text-align: center;width: 60px'"
        >
          <template #body="slotProps">
            <router-link
              v-if="slotProps.data && slotProps.data.number !== null"
              :to="{
                name: 'UserEditPage',
                params: { userNumber: slotProps.data.number },
              }"
            >
              {{ slotProps.data.number.toString().padStart(4, '0') }}
            </router-link>
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['number']" class="p-column-filter" />
          </template>
        </Column>

        <Column field="lastname" :header="$t('lastname')" sortable filterMatchMode="contains">
          <template #body="slotProps">
            <span v-if="slotProps.data && slotProps.data.lastname !== null">{{
              slotProps.data.lastname
            }}</span>
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['lastname']" class="p-column-filter" />
          </template>
        </Column>

        <Column field="firstname" :header="$t('firstname')" sortable filterMatchMode="contains">
          <template #body="slotProps">
            <span v-if="slotProps.data && slotProps.data.firstname !== null">{{
              slotProps.data.firstname
            }}</span>
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['firstname']" class="p-column-filter" />
          </template>
        </Column>

        <Column field="email" :header="$t('email')" sortable filterMatchMode="contains">
          <template #body="slotProps">
            <a
              v-if="slotProps.data && slotProps.data.email !== null"
              :href="'mailto:' + slotProps.data.email"
              >{{ slotProps.data.email }}</a
            >
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['email']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="client.name"
          :header="$t('client')"
          :sortable="true"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span
              v-if="slotProps.data && slotProps.data.client && slotProps.data.client.name !== null"
              >{{ slotProps.data.client.name }}</span
            >
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['client.name']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="role"
          :header="$t('role')"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ width: '110px' }"
          :bodyStyle="'text-align: center;width: 110px'"
        >
          <template #body="slotProps">
            <b-badge
              v-if="slotProps.data && slotProps.data.role !== null"
              :variant="userRoleColor(slotProps.data.role)"
            >
              {{ $t(slotProps.data.role) }}
            </b-badge>
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['role']" class="p-column-filter" />
          </template>
        </Column>
      </DataTable>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import { getUserRoleColor } from '@/helpers/colors';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PageHeader from '@/components/PageHeader';
import Dropdown from 'primevue/dropdown';

export default {
  name: 'UsersPage',
  components: {
    Widget,
    Breadcrumbs,
    DataTable,
    InputText,
    Column,
    PageHeader,
    // Dropdown,
  },
  data() {
    return {
      loading: true,
      filters: {},
    };
  },
  computed: {
    ...mapGetters(['getUsers', 'isLoading', 'getClients']),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('users') }];
    },
  },
  methods: {
    ...mapActions(['fetchUsers', 'fetchClients']),
    userRoleColor($role) {
      return getUserRoleColor($role);
    },
  },
  async created() {
    await this.fetchUsers();
    await this.fetchClients();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
</style>
