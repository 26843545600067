<template>
  <div class="page-header">
    <h1 class="page-title">
      <slot></slot>
    </h1>
    <LoadingSuccessSpinner />
    <b-alert show dismissible v-if="getFlashMessage.message" :variant="getFlashMessage.type">
      <span v-html="getFlashMessage.message" />
    </b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingSuccessSpinner from '@/components/LoadingSuccessSpinner.vue';

export default {
  name: 'PageHeader',
  components: {
    LoadingSuccessSpinner,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isLoading', 'isSuccess', 'getFlashMessage']),
  },
  methods: {
    ...mapActions(['fadeFlashMessage']),
  },
};
</script>

<style scoped>
.page-header {
  padding-bottom: 1rem;
  display: inline-block;
  width: 100%;
}

.page-title {
  display: inline-block;
  margin-right: 0.3em;
}
</style>
